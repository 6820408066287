
.starHeader {
    box-shadow: 0px 5px 10px 0px rgba(65, 60, 60, 0.75);
}

.logo {
    position: absolute;
}

.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

form {
    box-sizing: border-box;
    text-align: center;
}

form input {
    width: 300px;
    height: 25px;
    border: 1px solid rgb(124, 124, 124);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
}

.launch {
    margin: 10px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    padding: 8px;
}

.rocketIcon {
    padding-right: 2px;
}

.scanAlert {
    position: absolute;
    width: 400px;
    right: 50%;
    top: 30%;
}

.scanBox {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    align-items: center;
}

.toteBox {
    display: flex;
    align-content: center;
    padding-top: 20px;
}

.toggleConfig {
    display: flex;
    padding: 20px;
    align-items: center;
}

.configBody {
    padding-top: 5px;
    justify-content: center !important;
}

.configSelect {
    flex: 1;
    display: flex;
    justify-content: center;
    
}

.configBox {
    display: flex;
    flex-direction: column;
}

.glBox {
    display: flex;
    flex-direction: column;
}

.selectGl {
    display: flex;
    justify-content: center;
}

.glButton {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.sizeSmall {
    order: 1;
}

.pickButton {
    width: 200px;
}

.selectContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
}

.radarContainer {
    align-items: center;
}

.moveSpace {
    animation-name: myMove;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 6s;
}

@keyframes myMove {
    from { transform: translateY(0px);}
    50% { transform: translateY(-40px);}
    to {transform: translateY(40px)}
}

.textColor {
    color: black !important;
}

.editContainer {
    display: flex;
    justify-content: center;
    margin-top: 200px;
}

.editButton {
    margin: 5px;
}
